@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('./HG.css');
@import url('./S64.css');
@import url('./ODDO.css');

@layer base {
    :root {
        --color-error: 187, 6, 6;
        --color-white: 255, 255, 255;
        --color-logo-blue: 0, 55, 110;
    }
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.canvas-fluid {
    width: 100% !important;
    height: 100% !important;
}

.logo {
    width: var(--logo-width);
    object-fit: contain;
}

#root,
html,
body {
    background-color: white;
    scroll-behavior: smooth;
    height: max-content;
}

body {
    color: #00376e;
}

main {
    flex: 1 1 auto;
}

a {
    color: #00376e;
}

@media (min-width: 640px) {
    table.data-tables {
        display: inline-table !important;
    }

    table.data-tables thead tr:not(:first-child) {
        display: none;
    }
}

table.data-tables td:not(:last-child) {
    border-bottom: 0;
}

table.data-tables th:not(:last-child) {
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}

table.data-tables-admin th:first-child {
    border-radius: 6px 0 0 6px;
}

table.data-tables-admin th:last-child {
    border-radius: 0 6px 6px 0;
}

@media (min-width: 768px) {
    #modal-app.md\:w-80 {
        width: 20rem !important;
    }
}

.wmde-markdown,
.w-md-editor {
    font-family: 'Avenir Next', Helvetica, sans-serif !important;
    color: #00376e !important;
}

.wmde-markdown ul,
.wmde-markdown ol {
    list-style: disc;
}

.multiselect-error {
    background-color: rgba(127, 29, 29, 0.1);
    border: 1px solid red;
    border-radius: 5px;
}

@media (max-width: 1024px) {
    .w-md-editor-toolbar {
        overflow-x: scroll;
    }
    .w-md-editor-toolbar ul {
        display: flex;
        flex-direction: row;
    }
}

.react-pdf__Page__svg {
    margin: 0 auto;
}

.list-roman {
    list-style-type: upper-roman;
}

.pdf-page > canvas {
    max-width: 100%;
    height: auto !important;
}

.subnavbar li:first-child::marker {
    content: '';
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

[role='tooltip'].popup-content {
    width: auto !important;
}

.react-datepicker-popper {
    z-index: 22;
}
