input:checked+.toggle-bg {
    background: #809BB6;
    border-color: #809BB6;
}

input:checked+.toggle-bg:after {
    border-color: #fff;
    transform: translateX(100%);
}

.toggle-bg:after {
    background: #fff;
    border-color: #d1d5db;
    border-radius: 9999px;
    border-width: 1px;
    box-shadow: var(--tw-ring-inset) 0 0 0 calc(var(--tw-ring-offset-width)) var(--tw-ring-color);
    content: "";
    height: 1.25rem;
    left: 0.125rem;
    position: absolute;
    top: 0.125rem;
    transition-duration: .15s;
    transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-backdrop-filter;
    width: 1.25rem;
}

/*input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #cfc2f5;
}

input:checked ~ .radio {
  color:white;
  transform: translateX(100%);
  background-color: #cfc2f5;
}*/
