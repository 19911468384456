[data-theme='HG'] {
    --color-white: 240, 238, 232;
    --color-logo-blue: 51, 60, 73;
    --color-pill-bg: 204, 204, 204;
    --color-pill-active-bg: 255, 255, 255;
    --logo-width: 7rem;
    --color-footer-bg: 51, 60, 73;
    --color-product-card-bg: 0, 55, 100;
    --color-header-bg: 240, 238, 232;
}
