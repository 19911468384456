[data-theme='ODDO'] {
    --color-white: 247, 242, 238;
    --color-logo-blue: 0, 55, 110;
    --color-pill-bg: 128, 155, 182;
    --color-pill-active-bg: 255, 255, 255;
    --logo-width: 5rem;
    --color-footer-bg: 0, 60, 60;
    --color-product-card-bg: 0, 60, 60;
    --color-header-bg: 247, 242, 238;
    --font-family: 'Lato', sans-serif;
}

[data-theme='ODDO'] * {
    font-family: 'Lato', sans-serif;
}
